window.BlazorOpenModal = function(dialog) {
    if (dialog && !dialog.open) {
        dialog.showModal();
        dialog.setAttribute('aria-hidden', 'false')
    }
}


window.BlazorCloseModal = function (dialog) {
    if (dialog && dialog.open) {
        dialog.close();
        dialog.setAttribute('aria-hidden', 'true')
    }
}
